<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.devices.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.devices.tabs.tips.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(tip, index) in item.tips"
                  :id="'tip' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      :label="$t('form.tip.label')"
                      :label-for="'tips.' + index + '.tip_id'"
                      :state="errors && errors['tips.' + index + '.tip_id'] ? false : null"
                    >
                      <v-select
                        :id="'tips.' + index + '.tip_id'"
                        v-model="tip.tip_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tipsOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'tips.' + index + '.tip_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['tips.' + index + '.tip_id']">
                        {{ errors['tips.' + index + '.tip_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      :label-for="'tips.' + index + '.position'"
                      :state="errors && errors['tips.' + index + '.position'] ? false : null"
                    >
                      <b-form-input
                        :id="'tips.' + index + '.position'"
                        v-model="tip.position"
                        :input-id="'tips.' + index + '.position'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['tips.' + index + '.position']">
                        {{ errors['tips.' + index + '.position'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      block
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeTip(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addTip"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row v-if="errors && errors.models">
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.models[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'problems-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      tipsOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/tips', { params: { status: 'enabled', per_page: 500 } })
      .then(response => {
        this.tipsOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.item = await this.transformData()
  },
  methods: {
    async onSubmit() {
      const data = {
        status: this.item.status,
        title: this.item.title,
        description: this.item.description,
        tips: this.item.tips,
      }

      this.$http.post('/api/problems', data)
        .then(res => {
          router.replace({
            name: 'problems-update',
            params: { id: res.data.data.id },
            query: { ...this.$route.query, ...{ created: Math.floor(Date.now() / 1000) } },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addTip() {
      this.item.tips.push({
        position: 1,
        tip_id: null,
      })
    },
    removeTip(index) {
      this.item.tips.splice(index, 1)
    },
    transformData() {
      const data = {
        status: 'enabled',
        tips: [],
        title: {},
        description: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
